import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ButtonStyle = styled.button`
  border: 1px solid #caad7d;
  color: #caad7d;
  padding: 0.5rem;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 1rem 0;
  transition: ease-in-out 0.3s;
  :hover {
    background-color: #caad7d;
    color: white;
  }
`

const Button = ({ children, link }) => {
  return (
    <Link to={link}>
      <ButtonStyle>
        <p>{children}</p>
      </ButtonStyle>
    </Link>
  )
}

export default Button

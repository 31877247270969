import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { BigHeadline, Headline } from "./styles/headlines"

import SalonVideo from "../images/salon/salon-vid-n.mp4"

const SalonSection = () => (
  <section
    id="salon"
    className="bg-bg mt-28 pt-16 pb-9"
    style={{ scrollMarginTop: "140px" }}
  >
    <div className="container px-6 mx-auto">
      <BigHeadline>Salon</BigHeadline>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-28">
        <div>
          <p>
            Nimm Platz auf einem der 16 Bedienplätze unseres Salons. Die
            Ausstattung ist ein Mix aus Modernem Chic und Industry Style. Du
            kommst mit dem Auto? Wir bieten kostenfreie Parkplätze direkt vorm
            Salon. Darf es noch etwas mehr sein? Während unsere Profis Dich und
            deine Haare verwöhnen, bieten wir außerdem:
          </p>
          <ul className="mt-3">
            <li>• kostenfreies WIFI</li>
            <li>• Kaltgetränke</li>
            <li>• Kaffeespezialitäten</li>
          </ul>
        </div>
        {/* <div>
        <Headline>Mache jetzt einen Termin!</Headline>
          <p className="mb-6">
            Bequem und einfach online Deinen Termin aussuchen.
          </p>

          <a
            className="text-white font-headline tracking-wider uppercase p-3 mt-6 bg-main cursor-pointer  hover:bg-white hover:text-main text-sm cursor-pointer transition duration-200 ease-in-out"
            href="https://www.e-cut.de/online-buchung/index.php?salon=novoshair_alsfeld"
            rel="noopener noreferrer"
            title="Termin buchen"
            target="_blank"
          >
            Los geht's!
          </a>
        </div> */}
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-16 mb-24">
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt=""
        src="../images/salon/salon-4n.jpg"
      />
      <div className="lg:col-span-2 lg:row-span-2">
        <video controls muted className="h-full object-cover">
          <source src={SalonVideo} type="video/mp4" />
        </video>
      </div>
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt="Bild von den Geschäftsräumen"
        src="../images/salon/salon-2n.jpg"
      />
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt="Bild von den Geschäftsräumen"
        src="../images/salon/salon-3n.jpg"
      />
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt="Bild von den Geschäftsräumen"
        src="../images/salon/salon-1n.jpg"
      />
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt="Bild von den Geschäftsräumen"
        src="../images/salon/salon-5n.jpg"
      />
    </div>
  </section>
)
export default SalonSection

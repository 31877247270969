import React from "react"
import styled from "styled-components"

import { Headline, Subline, BigHeadline } from "./styles/headlines"

import Kim from "../images/team/kim.jpg"
import Lavinia from "../images/team/lavinia.jpg"
import Manuela from "../images/team/manuela.jpg"
import Eva from "../images/team/eva.jpg"
import Elena from "../images/team/elena.jpg"
import Ayline from "../images/team/ayline.jpg"
import Roberta from "../images/team/roberta.jpg"
import Laura from "../images/team/laura.jpg"
import Team from "../images/team/team.jpg"

const ButtonStyle = styled.button`
  border: 1px solid #caad7d;
  color: #caad7d;
  padding: 0.5rem;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 1rem 0;
  transition: ease-in-out 0.3s;
  :hover {
    background-color: #caad7d;
    color: white;
    border: 1px solid #caad7d;
  }
`

const Member = ({ img, name, profession, imgDesc }) => {
  return (
    <div className="flex flex-col" style={{ maxWidth: "400px" }}>
      <div style={{ maxHeight: "200px", overflow: "hidden" }}>
        <img
          alt={imgDesc}
          src={img}
          className="object-cover lg:transform lg:hover:scale-105 duration-1000"
        />
      </div>
      <div className="bg-bg px-4 py-6 flex-grow">
        <Headline>{name}</Headline>
        <Subline>{profession}</Subline>
      </div>
    </div>
  )
}

const TeamSection = ({ member }) => (
  <section id="team" className=" mt-24" style={{ scrollMarginTop: "140px" }}>
    <div className="container px-6 mx-auto">
      <BigHeadline>Team</BigHeadline>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-8">
        <Member
          imgDesc="Bild des Geschäftsführers Kim"
          img={Kim}
          name="Kim Kevin Brassel"
          profession="Saloninhaber, Wella Master of Color Expert, Wella Passionista"
        />
        <Member
          imgDesc="Bild von der Mitarbeiterin Eva"
          img={Eva}
          name="Eva Brassel"
          profession=" Technische Betriebsleiterin, Ausbildungsleitung,
        Über 32 Jahre Berufserfahrung,
        Black Star Calligraphistin"
        />
        <Member
          imgDesc="Bild von der Mitarbeiterin Manuela"
          img={Manuela}
          name="Manuela Grünewald"
          profession="Pivot Point geprüft,
        Wella Fachtrainerin,
        Black Star Calligraphistin"
        />
        <Member
          imgDesc="Bild von der Mitarbeiterin Elena"
          img={Elena}
          name="Elena Steinbrecher"
          profession="Stylistin, Black Star Calligraphistin,Geprüfte Kosmetikerin"
        />

        <Member
          imgDesc="Bild von der Mitarbeiterin Laura"
          img={Laura}
          name="Laura Koscinska"
          profession="Hair Stylistin, Friseur Gesellin, Mobile Hochzeitsdienste"
        />

        <Member
          imgDesc="Bild von der Auszubildenden Ayline"
          img={Ayline}
          name="Ayline Musaev"
          profession="Hair Stylistin i.A., Makeup Specialist Wedding"
        />
        <Member
          imgDesc="Bild von der Mitarbeiterin Roberta"
          img={Roberta}
          name="Roberta Mollame, B.A."
          profession="Professional Make up and Hair Stylist,
          Make up specialist, zertifiziert von der Famous Face Academy (freelancer)"
        />
        <Member
          imgDesc="Bild von der Mitarbeiterin Lavinia"
          img={Lavinia}
          name="Lavinia Engel"
          profession="Fotografin (Freelancer)"
        />
      </div>
      <div className="grid place-items-center mt-16">
        <div className="flex flex-col max-w-prose w-full">
          <div style={{ maxHeight: "300px", overflow: "hidden" }}>
            <img
              alt="Team"
              src={Team}
              className="object-cover lg:transform lg:hover:scale-105 duration-1000"
            />
          </div>
          <div className="bg-bg px-4 py-6 flex flex-col items-center">
            <Headline>Werde Teil des Teams!</Headline>
            <ButtonStyle>
              <a
                href="mailto:brassel.kim@gmx.de?subject=Bewerbung"
                title="Bewerbungsmail senden"
              >
                jetzt bewerben
              </a>
            </ButtonStyle>
          </div>
        </div>
      </div>
    </div>
  </section>
)
export default TeamSection

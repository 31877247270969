import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { BigHeadline, Subline } from "../components/styles/headlines"
import ServiceSection from "../components/service"
import TeamSection from "../components/team"
import SalonSection from "../components/salon"
import ReferenceSection from "../components/reference"

import Novus from "../images/novus_bg.png"
import Maps from "../images/maps.jpg"

import BrandOne from "../images/brands/wella.jpg"

import BrandTwo from "../images/brands/calligraphy-cut.jpg"

import BrandFour from "../images/brands/ghd.svg"

import BrandSix from "../images/brands/koleston.png"

import BrandSeven from "../images/brands/magma.png"

import BrandEight from "../images/brands/sebastian-professional.svg"

import BrandNine from "../images/brands/system-professional.jpg"

import BrandTen from "../images/brands/welonda.png"

import BrandEleven from "../images/brands/lavinia_photo.svg"

import BrandTwelve from "../images/brands/logo_villa_schloss.svg"

import Gg from "../images/brands/GG.jpg"

import Campus from "../images/brands/CAMPUS.png"

const SpecialHeadline = styled.h2`
  max-width: 400px;
  position: relative;
  ::after {
    content: url(${Novus});
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.15;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    {/************************************************************************************************** Intro Section */}
    <section className="container px-6  mx-auto grid md:grid-cols-2 gap-12 mt-60 mb-20">
      <BigHeadline>
        Hey! Willkommen bei Novus Hair & Colour Artists!
      </BigHeadline>
      <div className="justify-items-start">
        <p>
          Im Salon Novus ist es unser höchstes Gut, dauerhaft zufriedene und
          glückliche Kund:innen zu bedienen. Lass Dich durch die Qualität
          unserer Leistungen und Produkte überzeugen. Wir streben ständig
          danach, unserer Kundschaft die bestmögliche Leistung zu einem
          angemessenen Preis zu bieten. Qualität bedeutet für uns auch eine
          gute, typgerechte und zielführende Beratung.
        </p>{" "}
        <br />
        <p>
          Werte wie Toleranz, Respekt, Freundlichkeit stehen bei uns an erster
          Stelle. Unser Team ist absolut offen gegenüber Neuem. Das gesamte Team
          verfügt über ein hohes technisches Knowhow und langjährige Erfahrung.
          Um Dir dein bestes Erlebnis zu bieten, schulen wir uns zudem
          regelmäßig weiter. <br /> Wir freuen uns auf Dich!
        </p>
      </div>
    </section>
    {/************************************************************************************************** BackgroundImage Section */}
    <div>
      <StaticImage
        layout="fullWidth"
        placeholder="blurred"
        alt="Ein Bild des Salons"
        className="object-cover object-center w-full h-full"
        src="../images/background-n.jpg"
        style={{ maxHeight: `800px` }}
      />
    </div>
    {/************************************************************************************************** Motto Section */}
    <section className="container px-6 mx-auto grid md:grid-cols-2 gap-12 mt-28">
      <SpecialHeadline className="text-5xl font-normal leading-snug pb-2">
        Too much is never enough
      </SpecialHeadline>
      <div className="justify-items-start">
        <p>
          „Hinter dem Satz „too much is never enough“ steht mehr als ein
          witziger Gedanke oder Ironie. Dahinter steht ein Lebensgefühl. Wir
          sind nie zu viel. Nie zu laut. Nie zu bunt. Nie zu individuell. Nie zu
          frei. Nein, es ist niemals genug LAUT zu sein, BUNT zu sein,
          INDIVIDUELL zu sein, FREI zu sein. Wer sagt uns, wann etwas zu viel
          ist? Durchbrech doch mal die Grenzen in deinem Kopf. Sei doch mal
          wieder du selbst! Egal, was andere denken und vergesst niemals: too
          much of YOU is never enough!"
        </p>
      </div>
    </section>
    {/************************************************************************************************** Reference Section */}
    <ReferenceSection />
    {/************************************************************************************************** Service Section */}
    <ServiceSection />
    {/************************************************************************************************** Salon Section */}
    <SalonSection />
    {/************************************************************************************************** Team Section */}
    <TeamSection />
    {/************************************************************************************************** Partner & Reference Section */}
    <section id="salon" className="mt-24" style={{ scrollMarginTop: "140px" }}>
      <div className="container px-6  mx-auto">
        <BigHeadline>Unsere Partner</BigHeadline>

        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 mt-2 justify-items-center items-center">
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Wella Logo"
            src={BrandOne}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Calligraphy-cut Logo"
            src={BrandTwo}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="ghd Logo"
            src={BrandFour}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Galfe Geschwister Logo"
            src={Gg}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="System-Professional Logo"
            src={BrandNine}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Sebastian Logo"
            src={BrandEight}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Welonda Logo"
            src={BrandTen}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Campus Logo"
            src={Campus}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Koleston logo"
            src={BrandSix}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Magma Logo"
            src={BrandSeven}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Lavinia Logo"
            src={BrandEleven}
            width="200px"
          />
          <img
            className="lg:opacity-40 lg:hover:opacity-100 transition duration-300 ease-in-out"
            alt="Schloss Romrod / Villa Raab Logo"
            src={BrandTwelve}
            width="200px"
          />

        </div>
      </div>
    </section>
    <section
      id="kontakt"
      className="container px-6  mx-auto mt-28"
      style={{ scrollMarginTop: "140px" }}
    >
      <BigHeadline>Kontakt</BigHeadline>
      <div className="mt-9 grid grid-cols-1 md:grid-cols-2">
        <div>
          <strong>Novus Hair & Colour Artists</strong>
          <p>
            Alte Molkerei <br />{" "}
            Altenburger Str. 40 <br />{" "}
            36304 Alsfeld{" "}
          </p>
          <div className="mt-3">
            <a href="tel:066319116950" title="anrufen">
              Telefon: 06631 9116950
            </a>{" "}
            <br />
            <a href="mailto:info@novus-hair.de" title="Mail schreiben">
              E-Mail: info@novus-hair.de
            </a>
            <p></p>
          </div>
          <div
            className="grid grid-cols-1 md:grid-cols-2 gap-1 mt-16"
            style={{ maxWidth: "430px" }}
          >
            <Subline>Montag</Subline>
            <p className="text-main">geschlossen</p>
            <Subline>Dienstag</Subline>
            <p className="text-main">09:00 - 18:00 Uhr</p>
            <Subline>Mittwoch</Subline>
            <p className="text-main">09:00 - 18:00 Uhr</p>
            <Subline>Donnerstag</Subline>
            <p className="text-main">09:00 - 18:00 Uhr</p>
            <Subline>Freitag</Subline>
            <p className="text-main">09:00 - 19:00 Uhr</p>
            <Subline>Samstag</Subline>
            <p className="text-main">
              09:00 - 14:00 Uhr <br /> (jede 2. Woche bis 16:00 uhr)
            </p>
          </div>
        </div>
        <div className="hidden md:block">
          <a
            href="https://www.google.com/maps/dir//NOVUS+hair+%26+colour+artists,+Altenburger+Str.+40,+36304+Alsfeld/@50.7465564,9.2705637,18z/"
            title="Google Maps Route"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt="Google Maps Bild"
              src={Maps}
              className="h-full w-full object-cover"
            />
          </a>
        </div>
      </div>
    </section>
    <div className="md:hidden mt-12">
      <a
        href="https://www.google.com/maps/dir//NOVUS+hair+%26+colour+artists,+Altenburger+Str.+40,+36304+Alsfeld/@50.7465564,9.2705637,18z/"
        title="Google Maps Route"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="Google Maps Bild"
          src={Maps}
          className="h-full w-full object-cover"
        />
      </a>
    </div>
  </Layout>
)

export default IndexPage

import React from "react"

import { Headline, Subline, BigHeadline } from "./styles/headlines"

import Button from "./styles/button"

import scissorIcon from "../images/icons/scissor.svg"
import makeUpIcon from "../images/icons/makeup.svg"
import eyelashesIcon from "../images/icons/eyelashes.svg"

const Card = ({ children, headline, details, img }) => {
  return (
    <div className="bg-bg py-9 px-7 sm:px-10 rounded-sm">
      <div className="flex flex-col items-center">
        <img className="mb-3" src={img} alt="Icon" width="35" />
        <Headline>{headline}</Headline>
      </div>
      <div className="mt-7">
        <Subline>{details}</Subline>
        <p className="mt-4">{children}</p>
      </div>
    </div>
  )
}

const ServiceSection = ({ card }) => (
  <section
    id="service"
    className="container px-6  mx-auto mt-24 "
    style={{ scrollMarginTop: "140px" }}
  >
    <BigHeadline>Service</BigHeadline>
    <div className="flex flex-col items-center">
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16 mt-5 mb-12">
        <Card headline="Gesicht" details="schminken. stylen. " img={makeUpIcon}>
          (K)ein Auftritt wie in Hollywood?! Spotlight on. Dein Makeup in
          Perfektion. Ein Look, der das Beste in Dir zum Vorschein bringt. All
          eyes on YOU.
        </Card>
        <Card
          headline="Haare"
          details="schneiden. waschen. färben. tönen. stylen. "
          img={scissorIcon}
        >
          Spliss bereitet Dir Alpträume?! Kein Bock auf Blocksträhnen. Wir
          bieten deinen Haaren das beste Erlebnis um Dich noch schöner zu machen
          – deswegen sind alle Colour Artists perfekt geschult: CalligraphyCut
          [Blackstar], trendgerechte Haarschnitte, Balayage-Techniken, Styling
          und Pflege.
        </Card>
        <Card
          headline="Augen"
          details="wimpern färben. augenbrauen zupfen. lifting. "
          img={eyelashesIcon}
        >
          (K)ein perfekter Wimpernschlag?! Augen in die man sich gerne verliebt.
          Du sehnst Dich nach geschwungenen Wimpern! Welches Augenpaket passt zu
          Dir? Ob natürlich oder dramatisch – lass es uns gemeinsam
          herausfinden!
        </Card>
      </div>
      <Button link="/preise">Preise & Leistungen</Button>
    </div>
  </section>
)

export default ServiceSection

import React from "react"

import { BigHeadline } from "./styles/headlines"
import Button from "./styles/button"

import Prize from "../images/icons/prize2.svg"

const ReferenceSection = () => (
  <section
    className="container px-6 mx-auto mt-24"
    style={{ scrollMarginTop: "140px" }}
  >
    <BigHeadline>Referenzen</BigHeadline>
    <div className="flex flex-col items-center mt-5">
      <div className="flex flex-col items-center">
        <img alt="Auszeichnungs Icon" src={Prize} width={35} />

        <p className="font-headline tracking-wider uppercase text-xs text-center leading-relaxed pt-3">
          {" "}
          <span className="text-main text-sm">2019-2021</span> <br />
          <span className="text-main text-sm">
            Teilnahme Wella Trend Vision Award
          </span>{" "}
          <br />
          3. Platz Balayage Experte Deutschland <br />
          3. Platz Colour Artist Deutschland <br />
          2. Platz NTVA Balayage <br />
          1. Platz NTVA Balayage <br />
          3. Platz Colour Artist Deutschland
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12  max-w-screen-lg md:gap-24 mt-12 mb-12">
        <div className="flex flex-col items-center">
          <img alt="Auszeichnungs Icon" src={Prize} width={35} />
          <p className="font-headline tracking-wider uppercase text-xs text-center leading-relaxed pt-3">
            {" "}
            <span className="text-main text-sm">2016</span> <br />
            <span className="text-main text-sm">
              Teilnahme Landesmeisterschaft
            </span>{" "}
            <br />
            2. Platz
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img alt="Auszeichnungs Icon" src={Prize} width={35} />
          <p className="font-headline tracking-wider uppercase text-xs text-center leading-relaxed pt-3">
            {" "}
            <span className="text-main text-sm">2016</span> <br />
            <span className="text-main text-sm">
              Innungsbester Landesmeisterschaft
            </span>{" "}
          </p>
        </div>
      </div>
      {/************************************************ Secondary Prizes */}
      <div className="grid gridcols-1 md:grid-cols-4 gap-12 mt-3">
        <p className="text-main font-headline tracking-wider uppercase text-sm text-center">
          Teilnahme an Modeevents
        </p>
        <p className="text-main font-headline tracking-wider uppercase text-sm text-center">
          Moderation von Trend-Shows
        </p>
        <p className="text-main font-headline tracking-wider uppercase text-sm text-center">
          Master of Color Expert Wella
        </p>
        <p className="text-main font-headline tracking-wider uppercase text-sm text-center">
          Wella Passionista
        </p>
      </div>
    </div>
    <div class="w-full flex justify-center mt-12">
      <Button link="galerie">Looks ansehen</Button>
    </div>
  </section>
)
export default ReferenceSection
